WinJS.Application.onready = (eventInfo: CustomEvent) => {
    WinJS.strictProcessing();

    WinJS.UI.processAll()
        .then(postProcess);

    function postProcess(value: any) {
        WinJS.Utilities.startLog();


    }
};


WinJS.Application.start();